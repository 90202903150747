/**
 * @description: 管理员页面接口列表
 */

import request from './request'

/**
* @description: 获取管理员列表信息
* @param {
*   pageSize: Number, 页面size
*   pageNumber: Number, 当前页
* }
*/
export function get_admin_list (data) {
  return request({
    url: '/user_api/users',
    method: 'post',
    data
  })
}

/**
* @description: 添加管理员
* @param {
*   LoginName: String, 用户名
*   Password: String, 密码
*   RePassword: String, 确认密码
*   RoleID: Number, 角色
*   UserStatus: Number, 管理员状态
*   Domain: String, 域名
*   GroupID: String, 频道组ID
*   PowzaUserName: String, 停流账户
*   PowzaPassword: String, 停流密码
*   RealName: String, 真实姓名
*   Email: String, 邮箱
*   Region: String, 区域
*   Topology: String, 拓扑
*   ChannelGroup: String, 频道
*   Type: "1"
* }
*/
export function add_admin (data) {
  return request({
    url: '/user_api/user',
    method: 'post',
    data
  })
}

/**
* @description: 修改管理员信息
* @param {
  *   LoginName: String, 用户名
  *   Password: String, 密码
  *   RePassword: String, 确认密码
  *   RoleID: Number, 角色
  *   UserStatus: Number, 管理员状态
  *   Domain: String, 域名
  *   GroupID: String, 频道组ID
  *   PowzaUserName: String, 停流账户
  *   PowzaPassword: String, 停流密码
  *   RealName: String, 真实姓名
  *   Email: String, 邮箱
  *   Region: String, 区域
  *   Topology: String, 拓扑
  *   ChannelGroup: String, 频道
  *   Type: "1"
  * }
  */
export function update_admin (data) {
  return request({
    url: '/user_api/user',
    method: 'put',
    data
  })
}

/**
* @description: 删除管理员
* @param {
*   SAIDs: Array, 管理员ID
* }
*/
export function delete_admins (data) {
  return request({
    url: '/user_api/user',
    method: 'delete',
    data
  })
}
