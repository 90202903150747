
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['roleRight'])
  },
  methods: {
    // 按钮权限
    hasRight (right) {
      return this.roleRight.includes(right)
    }
  }
}
