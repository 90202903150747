/**
 * @description: 角色页面接口列表
 */

import request from './request'

/**
 * @description: 根据role ID获取角色信息
 * @param {
 *  roleID: Number, 角色id
 * }
 */
export function get_role_by_id (roleID) {
  return request({
    url: `/role_api/role/${roleID}`,
    method: 'get'
  })
}

/**
* @description: 获取role列表角色信息
* @param {
*   pageSize: Number, 页面size
*   pageNumber: Number, 当前页
* }
*/
export function get_role_list (data) {
  return request({
    url: '/role_api/roles',
    method: 'post',
    data
  })
}

/**
 * @description: 添加角色
 * @param {
 *  RoleName: String, 角色名称
 *  RoleRight: String, 权限
 * }
 */
export function add_role (data) {
  return request({
    url: '/role_api/role',
    method: 'post',
    data
  })
}

/**
 * @description: 修改角色
 * @param {
 *  RoleName: String, 角色名称
 *  RoleRight: String, 权限
 * }
 */
export function update_role (data) {
  return request({
    url: '/role_api/role',
    method: 'put',
    data
  })
}

/**
 * @description: 删除角色
 * @param {
 *  role_ids: Array, 角色id数组
 * }
 */
export function delete_roles (data) {
  return request({
    url: '/role_api/roles',
    method: 'delete',
    data
  })
}

/**
 * @description: 查询所有权限(树形)
 */
export function get_funcs_tree () {
  return request({
    url: '/role_api/funcs_tree',
    method: 'get'
  })
}

/**
 * @description: 获取所有的role
 */
export function get_all_roles () {
  return request({
    url: '/role_api/roles',
    method: 'get'
  })
}
