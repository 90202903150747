<template>
  <div class="wraper">
    <div class="filter">
      <div class="msg">
        <el-input
          v-model="loginName"
          style="margin-right:20px"
          :placeholder="$t('common.username')"
          clearable
        />
        <el-select
          v-model="roleName"
          :popper-append-to-body="false"
          style="margin-right:20px"
          :placeholder="$t('admin.roleName')"
          clearable
        >
          <el-option
            v-for="item in roleNameOptions"
            :key="item.RoleID"
            :label="item.RoleName"
            :value="item.RoleName"
          />
        </el-select>
        <el-select
          v-model="adminStatus"
          :popper-append-to-body="false"
          style="margin-right:20px"
          :placeholder="$t('admin.adminStatus')"
          clearable
        >
          <el-option
            v-for="item in adminStatusOptions"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-button
          v-if="hasRight('1010204')"
          type="primary"
          :loading="queryLoading"
          @click="search"
        >
          {{ $t("common.find") }}
        </el-button>
      </div>
      <div class="operate">
        <el-button
          v-if="hasRight('1010201')"
          type="primary"
          @click="addAdmin"
        >
          {{ $t("admin.addAdmin") }}
        </el-button>
        <el-button
          type="primary"
          @click="getAdminList"
        >
          {{ $t("common.fresh") }}
        </el-button>
        <el-button
          v-if="hasRight('1010202')"
          type="danger"
          :disabled="chooseRows.length===0"
          @click="deleteSelected"
        >
          {{ $t("common.deleteSelect") }}
        </el-button>
      </div>
    </div>
    <div class="table">
      <el-table
        :data="adminData"
        border
        row-key="SAID"
        @select="handleSelect"
        @select-all="handleSelectAll"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="55"
        />
        <el-table-column
          prop="SAID"
          :label="$t('admin.adminID')"
          align="center"
        />
        <el-table-column
          prop="LoginName"
          :label="$t('admin.adminName')"
          align="center"
        />
        <el-table-column
          prop="RoleName"
          :label="$t('admin.roleName')"
          align="center"
        />
        <el-table-column
          prop="UserStatus"
          :label="$t('admin.adminStatus')"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag size="medium">
              {{ scope.row.UserStatus | adminStatusFilter }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="Email"
          :label="$t('common.email')"
          align="center"
        />
        <el-table-column
          :label="$t('common.operate')"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-button
              v-if="hasRight('1010203')"
              size="mini"
              @click="handleEdit(scope.$index, scope.row)"
            >
              {{ $t("common.edit") }}
            </el-button>
            <el-button
              v-if="hasRight('1010202')"
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
            >
              {{ $t("common.delete") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { get_admin_list, delete_admins } from '@/api/admin.js'
import { get_all_roles } from '@/api/role.js'
import { formatTime } from '@/common/util.js'
import myMixin from '@/mixin/'

export default {
  filters: {
    adminStatusFilter (value) {
      if (localStorage.lang === 'zh' || localStorage.lang === undefined) {
        if (value == 1) {
          return '正常'
        } else {
          return '禁用'
        }
      } else {
        if (value == 1) {
          return 'Normal'
        } else {
          return 'Disabled'
        }
      }
    }
  },
  mixins: [myMixin],
  data () {
    return {
      loginName: '',
      roleName: '',
      adminStatus: null,
      adminStatusOptions: [{
        label: this.$t('common.normal'),
        value: 1
      }, {
        label: this.$t('common.disable'),
        value: 2
      }],
      adminData: [],
      currentPage: 1,
      pageSize: 10,
      total: null,
      chooseRows: [],
      queryLoading: false,
      roleNameOptions: []
    }
  },
  created () {
    this.pageSize = Number(localStorage.adminPageSize) || 10
    this.getAllRoles()
    this.getAdminList()
  },
  methods: {
    // 选中表单数据，将SAID保存到this.chooseRows
    handleSelect (selection) {
      this.chooseRows = selection.map((item) => {
        return item.SAID
      })
    },
    // 获取所有角色
    async getAllRoles () {
      const res = await get_all_roles()
      if (res.data.code === 0) {
        this.roleNameOptions = res.data.data
      }
    },
    // 全选
    handleSelectAll (selection) {
      this.chooseRows = selection.map((item) => {
        return item.SAID
      })
    },
    // 查询
    async search () {
      this.queryLoading = true
      await this.getAdminList()
      this.$message.success(this.$t('common.searchSuc'))
    },
    // 获取管理员数据
    getAdminList () {
      let dateArr = []
      if (this.dateRange) {
        dateArr = this.dateRange.map((item) => {
          return formatTime(item)
        })
      }
      const param = {
        page_num: this.currentPage,
        page_size: this.pageSize,
        order_by: 'SAID',
        sort: 1, // 1 降序, 0 升序
        query_condition: {
          LoginName: {
            value: this.loginName,
            type: 'str'
          },
          RoleName: {
            value: this.roleName,
            type: 'str'
          },
          'a.CreateTime': {
            value: dateArr,
            type: 'datetime_range'
          },
          UserStatus: {
            value: this.adminStatus,
            type: 'int'
          }
        }
      }
      get_admin_list(param).then((res) => {
        this.queryLoading = false
        if (res.data.code === 0) {
          this.total = res.data.data.user_count
          this.adminData = res.data.data.users
        }
      }).catch(() => {
        this.queryLoading = false
      })
    },
    // 修改pageSize
    handleSizeChange (size) {
      this.pageSize = size
      localStorage.adminPageSize = size
      this.getAdminList()
    },
    // 修改当前页
    handleCurrentChange (current_page) {
      this.currentPage = current_page
      this.getAdminList()
    },
    addAdmin () {
      this.$router.push('/user/add_admin')
    },
    // 删除选中
    deleteSelected () {
      this.deleteAdmins(this.chooseRows)
    },
    // 编辑
    handleEdit (index, row) {
      this.$router.push({ name: 'editAdmin', params: row })
    },
    // 单个删除
    handleDelete (index, row) {
      this.deleteAdmins([row.SAID])
    },
    // 删除管理员
    async deleteAdmins (SAIDs) {
      await this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      })
      const params = { SAIDs }
      const res = await delete_admins(params)
      if (res.data.code === 0) {
        this.$message.success(this.$t('common.deleteSuccess'))
        this.getAdminList()
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.wraper {
  padding-top: 20px;
  padding-right: 20px;
  display: flex;
  flex-flow: column;
  .filter {
    .operate {
      margin-top: 20px;
    }
  }
  .table {
    margin-top: 20px;
  }
  .page {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
}
</style>
